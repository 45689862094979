import clsx from "clsx";

import Target from "@components/Target";

const CategoryTokens = ({ categories, mainCount }) => {
	// For blog posts with more than one category, only display single "Blog" tag
	if (categories.length > 1 && categories[0].groupHandle === "blogCategories") {
		categories = [categories[0]];
  }

	return (
		<div className={clsx("CategoryTokens", "flex flex-wrap")}>
			{!!categories.length &&
        categories.map((category, index) => {
          const categoriesWithColor = ["topics", "blogCategories", "resourceTypes", "newsTypes", "industries"];
					return (
						<Target
							key={category.id}
							target={{ element: category, type: "entry" }}
							className={clsx(
								"CategoryTokens__token",
								"flex text-xs px-2 py-1 rounded-sm text-black-01 font-medium",
								"mr-1 last-of-type:mr-0 mb-1",
								"whitespace-nowrap",
								"hover:bg-black-01 hover:text-white-01",
								"transition-colors",
								{
                  "bg-green-01 text-white-01":
                    category.groupHandle === "topics",
                  "bg-yellow-01":
                    category.groupHandle === "blogCategories",
                  "bg-dark-purple":
                    category.slug === "webinars",
                  "bg-purple-01 text-white-01":
                    category.groupHandle === "resourceTypes" ||
                    category.groupHandle === "newsTypes",
                  "bg-beige-01 text-white-01":
                    category.groupHandle === "industries",
                  "bg-white-03": !categoriesWithColor.includes(category.groupHandle) && category.slug !== "webinars",
								},
							)}
						>
							{category.groupHandle === "attackTypes" && "Type: "}
							{category.groupHandle === "attackVectors" && "Vector: "}
							{category.groupHandle === "attackGoals" && "Goal: "}
							{category.groupHandle === "blogCategories"
								? "Blog"
								: category.slug === "threat-reports" ||
								  category.slug === "threat-intelligence-reports"
								? "Reports"
								: category.title}
						</Target>
					);
				})}
		</div>
	);
};

export default CategoryTokens;
